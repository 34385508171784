<template>
  <Layout>

    <a @click="this.$router.go(-1)">
      <font-awesome-icon icon="arrow-left" /> Back
    </a>
    <div class="card">
      <div class="card-header">
        User {{ form.name }}
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submitForm">
          <div class="">
            <label for="Name" class="col-sm-3 uk-form-label">Name</label>
            <div class="col-sm-9">
              <input type="text" class="uk-input" id="staticEmail" v-model="form.name">
            </div>
          </div>
          <div class="">
            <label for="email" class="col-sm-3 uk-form-label">Email</label>
            <div class="col-sm-9">
              <input type="text" class="uk-input" id="staticEmail" v-model="form.email">
            </div>
          </div>
          <div class="">
            <label for="password" class="col-sm-3 uk-form-label">Password</label>
            <div class="col-sm-9">
              <input type="password" class="uk-input" id="" v-model="form.password">
            </div>
          </div>
          <div class="">
            <label for="email" class="col-sm-3 uk-form-label">verified</label>
            <div class="col-sm-9">
              <input class="uk-checkbox" type="checkbox" value="" id="defaultCheck1" v-model="form.isEmailVerified">
            </div>
          </div>
          <div class="">
            <label for="email" class="col-sm-3 uk-form-label">Role (admin)</label>
            <div class="col-sm-9">
              <input type="text" class="uk-input" id="staticEmail" v-model="form.role">
              
            </div>
          </div>
          <div class="col-sm-9 uk-margin-top">
            <button class="uk-button uk-button-primary">Save</button>
          </div>
          <div class="form-group">
            <div v-if="messagefault" class="alert alert-danger" role="alert">
              {{ messagefault }}
            </div>
            <div v-if="messageok" class="alert alert-success" role="alert">
              {{ messageok }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/private.vue'
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

export default {
  name: "User View",
  components: { Layout },
  data() {
    return {
      id: this.$route.params.id,
      content: "User",
      loading: false,
      messagefault: "",
      messageok: "",
      form:
      {
        name: '',
        email: '',
        isEmailVerified: '',
        role: 'admin',
        password:'',
      }

    };
  },
  methods: {
    submitForm() {
      UserService.createUser(this.form).then(
        (response) => {
          this.messageok = "Saved succesfully";
          console.log(response)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error.message);
          this.messagefault = error.response.data.message;
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    goBack() {
      this.$router.go(-1)
    }
  },
};

</script>