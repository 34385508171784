import api from './api';
class UserService {
  getUsers() {
    return api.get('/users');
  }

  createUser(userBody) {
    const userconstuct = '/users/';
    return api.post(userconstuct, userBody);
  }

  getUser(id) {
    const userconstuct = '/users/'+id;
    return api.get(userconstuct);
  }
  updateUser(id, userBody) {
    const userconstuct = '/users/'+id;
    return api.patch(userconstuct,userBody);
  }
  updatePassword(id, userBody) {
    const userconstuct = '/auth/reset-password-admin/'+id;
    return api.post(userconstuct,userBody);
  }
}

export default new UserService();
